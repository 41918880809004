import styled from 'styled-components';
import { device } from '../../utils/breakpoints';

export const ProductsTitle = styled.div`
	background: #00a89d;
	border-radius: 5px;
	grid-column: span 2;
	display: flex;
	height: 60px;
	padding: 0 20px;
	align-items: center;
	justify-content: space-between;

	h2 {
		color: #fff;
		font-size: 25px;
		margin: 0;

		@media (min-width: 350px) {
			font-size: 28px;
		}
	}

	img {
		height: 23px;
		margin: 0;
	}

	@media ${device.tablet} {
		align-items: start;
		grid-column: span 1;
		height: 100%;
		flex-direction: column;
		padding: 20px 20px 25px 20px;

		h2 {
			padding-right: 20px;
			font-size: 32px;
		}

		img {
			align-self: flex-end;
			height: 25px;
		}
	}

	@media ${device.desktop} {
		align-items: center;
		grid-column: ${(props) => (props.articleBottom ? 'span 6' : props.article ? 'span 2' : null)};
		flex-direction: ${(props) => (props.article || props.articleBottom) && 'row'};
		height: ${(props) => (props.article || props.articleBottom) && '60px'};
		padding: ${(props) => (props.article || props.articleBottom ? '0 20px' : '25px')};
		h2 {
			font-size: ${(props) => (props.article || props.articleBottom ? '28px' : '40px')};
		}

		img {
			height: ${(props) => (props.article || props.articleBottom ? '23px' : '42px')};
			align-self: ${(props) => (props.article || props.articleBottom) && 'center'};
		}
	}
`;

export const Disclaimer = styled.p`
	font-size: 12px;
	line-height: 18px;
	grid-column: span 2;
	color: ${(props) => props.theme.colors.grey[2]};
	margin-top: ${(props) => (props.article ? '40px' : null)};
	margin-bottom: 0;

	br {
		display: none;
	}

	@media ${device.tablet} {
		margin-top: ${(props) => (props.article ? '10px' : null)};
		grid-column: span 4;
	}

	@media (min-width: 850px) {
		br {
			display: ${(props) => (props.article ? 'none' : 'block')};
		}
	}
	@media ${device.desktop} {
		grid-column: ${(props) => (props.article ? 'span 2' : props.articleBottom ? 'span 6' : null)};
	}
`;
