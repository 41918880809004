import React, { useRef, useState } from "react"
import { Waypoint } from "react-waypoint"
import { Formik } from "formik"
import Success from "../../images/success-tick.png"
import axios from "axios"

import {
  Overlay,
  Container,
  Caption,
  FormWrapper,
  ImageWrapper,
  CloseButton,
} from "./style.js"
import { NewsletterPopupData } from "../../hooks/newsletter-pop-up-data"
import useClose from "../../hooks/useClose"
import { ResponsiveGatsbyImage } from "../ResponsiveGatsbyImage"
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock"
import { ReactComponent as ErrorIcon } from "../../images/invalid-email.svg"

import { ReactComponent as Cross } from "../../images/close-btn.svg"
import { CheckCookie } from "../../utils/cookieHandler.js"
import Cookies from "js-cookie"

import { ErrorMessage } from "../SignUp/style.js"
const NewsletterPopup = () => {
  const modalRef = useRef(null)
  const newsletterPopup = NewsletterPopupData()
  const [show, setShow] = useState(false)

  const close = () => {
    if (show) {
      enableBodyScroll(modalRef.current)
      setTimeout(() => setShow(false), 50)
    }
  }
  useClose(modalRef, () => close())

  const {
    description,
    heading,
    image,
    imageCaption,
    inputPlaceholder,
    submitButtonText,
  } = newsletterPopup

  if (!heading) return null
  const cookieId = "seen-ig-popup"

  const handleWaypointCross = () => {
    const hasSeen = Cookies.get(cookieId)
    // const hasSeen = CheckCookie("seen-ig-popup", true, 30, true)

    if (!hasSeen) {
      Cookies.set(cookieId, true, { expires: 30 })
      setShow(true)

      setTimeout(() => {
        const storedRequestAnimationFrame = window.requestAnimationFrame

        window.requestAnimationFrame = () => 42
        disableBodyScroll(modalRef.current)
        window.requestAnimationFrame = storedRequestAnimationFrame
        // disableBodyScroll(modalRef.current)
      }, 50)
    }
  }

  return (
    <div>
      <Waypoint onEnter={handleWaypointCross} />
      {show && (
        <Overlay>
          <Container ref={modalRef}>
            <CloseButton onClick={() => close()}>
              <Cross />
            </CloseButton>
            <ImageWrapper>
              <ResponsiveGatsbyImage
                src={image.sourceUrl}
                srcSet={image.srcSet}
              />
              {imageCaption && (
                <Caption dangerouslySetInnerHTML={{ __html: imageCaption }} />
              )}
            </ImageWrapper>

            <Formik
              initialValues={{ email: "" }}
              validateOnChange={false}
              validate={values => {
                let errors = {}

                if (!values.email) {
                  errors.email = "Please enter a valid email address"
                  console.log("error1")
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                  console.log("error2")
                  errors.email = "Please enter a valid email address"
                }

                return errors
              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  setSubmitting(true)
                }, 400)

                const { email } = values
                console.log(email)

                axios
                  .post("/.netlify/functions/signUpHandler", {
                    email: email,
                    tags: ["community signup"],
                  })
                  .then(function(response) {
                    console.log(response)
                  })
                  .catch(function(error) {
                    console.log(error)
                  })
              }}
            >
              {({
                values,
                errors,
                handleChange,
                handleSubmit,
                isSubmitting,
                validateForm,
              }) => (
                <FormWrapper onSubmit={handleSubmit}>
                  {heading && <h1>{heading}</h1>}
                  {description && <h3>{description}</h3>}
                  {!isSubmitting ? (
                    <input
                      type="text"
                      name="email"
                      id="email"
                      placeholder={inputPlaceholder}
                      value={isSubmitting ? "" : values.email}
                      onChange={handleChange}
                      spellCheck={false}
                    />
                  ) : (
                    <h3 className="success">
                      <img src={Success} alt="" />
                      <p>
                        Thanks for subscribing! <br />
                        We'll be in touch soon.
                      </p>
                    </h3>
                  )}

                  {!isSubmitting && (
                    <button
                      type="submit"
                      onSubmit={handleSubmit}
                      onClick={() => {
                        validateForm()
                      }}
                      placeholder={inputPlaceholder}
                    >
                      {submitButtonText || "Submit"}
                    </button>
                  )}
                  <ErrorMessage
                    className="errorMessage"
                    showError={errors.email}
                    name="email"
                    style={{
                      display: errors.email ? "flex" : "none",
                    }}
                  >
                    <ErrorIcon />
                    <p>{errors.email}</p>
                  </ErrorMessage>
                </FormWrapper>
              )}
            </Formik>
          </Container>
        </Overlay>
      )}
    </div>
  )
}

export default NewsletterPopup
