import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { ProductsTitle, Disclaimer } from './style.js';
import { ProductsGrid } from '../TeaserGrids';
import { Teaser } from '../Teaser';
import ProductsIcon from '../../images/products-icon.png';

const Products = ({ title, article, articleBottom, marginTop }) => {
	const { wpgraphql } = useStaticQuery(graphql`
	
		query Products {
			wpgraphql {
				products {
					nodes {
						title
						productDetails {
							link
							image {
								sourceUrl
								srcSet
								mediaDetails {
									width
									height
								}
							}
						}
					}
				}
			}
		}
	`);

	const buttonLabels = [ 'Yes, please', 'Gimme', `I'll take two`, 'Hell yes!', 'Oh baby', 'Love it', 'I need this' ];
	const items = article || articleBottom ? wpgraphql.products.nodes.slice(0, 6) : wpgraphql.products.nodes;

	return (
		<ProductsGrid marginTop={marginTop} article={article} articleBottom={articleBottom}>
			<ProductsTitle article={article} articleBottom={articleBottom}>
				<h2>{title}</h2>
				<img src={ProductsIcon} alt="" />
			</ProductsTitle>
			{items.map((product, index) => {
				return (
					<Teaser
						key={index}
						product
						title={product.title}
						img={product.productDetails.image}
						link={product.productDetails.link}
						ctaLabel={buttonLabels[index]}
					/>
				);
			})}

			<Disclaimer article={article} articleBottom={articleBottom}>These are affiliate links to Amazon and other partner brands. We may earn a small commission if you click the link and make a purchase.<br/> There is no extra cost to you, so it’s just a nice way to help support the site.</Disclaimer>
		</ProductsGrid>
	);
};

export default Products;
