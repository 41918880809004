import { useStaticQuery, graphql } from "gatsby"

export const NewsletterPopupData = () => {
  const { wpgraphql } = useStaticQuery(
    graphql`
      query newsletterPopupConfig {
        wpgraphql {
          justins(where: { id: 101942 }) {
            nodes {
              title
              avSiteOptions {
                avNewsletterPopup {
                  image {
                    sourceUrl
                    srcSet
                    mediaDetails {
                      width
                      height
                    }
                  }
                  imageCaption
                  heading
                  description
                  inputPlaceholder
                  submitButtonText
                }
              }
            }
          }
        }
      }
    `
  )

  return wpgraphql?.justins?.nodes[0]?.avSiteOptions?.avNewsletterPopup
}
