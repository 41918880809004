import styled from "styled-components"
import { device } from "../../utils/breakpoints"

export const Overlay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 100;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
`
export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  background: white;
  width: calc(100% - 40px);
  position: relative;
  filter: drop-shadow(0px 15px 35px rgba(0, 0, 0, 0.35));
  max-height: 90%;
  overflow: scroll;

  ::-webkit-scrollbar {
    display: none;
  }
  @media ${device.tablet} {
    width: 600px;

    grid-template-columns: 280px 1fr;
  }

  @media ${device.desktop} {
    width: 782px;
    grid-template-columns: 363px 1fr;
  }
`

export const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 250px;
  background: teal;

  @media ${device.tablet} {
    height: 100%;
  }

  > div {
    width: 100%;
    height: 100%;
  }
`

export const Caption = styled.h3`
  position: absolute;
  bottom: 00px;
  left: 20px;
  color: white;
  font-size: 12px;

  a {
    text-decoration: underline;
    color: white;

    &:hover {
      text-decoration: none;
    }
  }

  p {
    margin: 0;
  }

  @media ${device.desktop} {
    left: 30px;
    bottom: 15px;
  }
`
export const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  padding: 20px;
  position: relative;
  margin: 0;
  @media ${device.tablet} {
    padding: 36px 30px;
  }

  @media ${device.desktop} {
    padding: 44px 40px;
  }

  h1 {
    color: black;
    margin-bottom: 16px;
    font-size: 36px;
    line-height: 1;
    @media ${device.desktop} {
      font-size: 42px;
    }
  }

  h3 {
    margin-bottom: 8px;
    font-weight: bold;
    color: black;
    font-size: 16px;
    line-height: 1.3;
    @media ${device.desktop} {
      font-size: 18px;
    }
  }

  input {
    border: none;
    border-bottom: 2px solid #19191a;
    padding: 0;
    padding: 16px 0;
    margin-bottom: 16px;
    color: black;
    font-weight: 600;
    outline: none;
    font-size: 16px;
  }

  input::placeholder {
    color: #545c52;
    opacity: 1;
  }

  button {
    display: inline-block;
    background: ${props => props.theme.colors.pink};
    transition: 70ms;
    color: white;
    font-weight: 600;
    padding: 10px 20px;
    height: 60px;
    outline: none;
    border: none;
    border-radius: 3px;
    cursor: pointer;

    :hover {
      opacity: 0.7;
      transition: 70ms;
    }
  }

  .errorMessage {
    left: 0px;
    right: 0px;
    bottom: 0px;
    position: static;
    margin-top: 10px;
  }

  .success {
    display: flex;

    font-weight: bold;

    margin-top: 30px;
    img {
      height: 30px;
      width: 30px;
      margin-right: 14px;
      margin-top: -5px;

      @media ${device.desktop} {
        margin-top: 4px;
      }
    }

    br {
      display: none;

      @media ${device.desktop} {
        display: block;
      }
    }
  }
`

export const CloseButton = styled.button`
  height: 38px !important;
  width: 38px !important;
  background: ${props => props.theme.colors.pink};
  transition: 150ms;
  @media ${device.tablet} {
    background: rgba(0, 0, 0, 5%);
  }
  @media ${device.desktop} {
    height: 48px;
    width: 48px;
  }
  position: absolute;
  z-index: 10;
  top: 0;
  right: 0;
  border: none;
  cursor: pointer;
  :hover {
    opacity: 0.8;
  }

  svg {
    width: 12px;
    height: 12px;
    margin-top: 3px;

    @media ${device.desktop} {
      width: 15px;
      height: 15px;
    }
    path {
      fill: white;

      @media ${device.tablet} {
        fill: #949993;
      }
    }
  }
`
